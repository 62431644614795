import { Alert, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { PopupInterface, PromoCodeSettingInterface } from '../../../../exports/Interfaces';
import useModals from '../../../../hooks/useModals';
import PopupManager from '../../../../services/api/PopupManager';
import ActionConfirmation from '../../../../components/popover/ActionConfirmation';
import { POPUP_TYPES } from '../../../../exports/Enums';
import MarketingManager from '../../../../services/api/marketing/MarketingManager';
import useRdxStore from '../../../../hooks/useRdxStore';

interface Props {
    popup: PopupInterface;
}

function PopupSection(props: Props): JSX.Element {
    const { popup } = props;

    const { store } = useRdxStore();

    const { setPopupModal } = useModals();

    const [loading, setLoading] = useState<boolean>(true);

    const [promoCodeSetting, setPromoCodeSetting] = useState<PromoCodeSettingInterface | null>(null);

    const [deleteClicked, setDeleteCLicked] = useState<boolean>(false);

    const getPromoCodeSetting = async (): Promise<void> => {
        setLoading(true);
        const { success, response } = await MarketingManager.getPromoCodeSetting(store?.franchise_slug);
        setLoading(false);
        if (success && response?.data?.data) {
            setPromoCodeSetting(response.data.data.settings);
        }
    };

    useEffect(() => {
        getPromoCodeSetting();
    }, []);

    const onEdit = (): void => {
        setPopupModal({ open: true, popup, type: popup.type ?? POPUP_TYPES.DEFAULT });
    };

    const onDelete = async (): Promise<void> => {
        PopupManager.delete(popup.id);
    };

    const renderContent = (): JSX.Element => {
        switch (popup.type) {
            case POPUP_TYPES.COLLECT_EMAILS:
                return <p>Promo Code</p>;
            default:
                return (
                    <>
                        <p>{popup?.title}</p>
                        <p>{popup?.description}</p>
                    </>
                );
        }
    };

    const renderWarning = useMemo(
        () =>
            !loading &&
            !promoCodeSetting &&
            popup.type === POPUP_TYPES.COLLECT_EMAILS && (
                <Alert
                    type="warning"
                    showIcon
                    className="mt-small"
                    message="No promo code or promo code settings have been configured for the website."
                    description="The website hosts multiple companies, but no master promo code has been set up for this restaurant group. In order to most effectively collect emails, either enable a master promo code for the entire restaurant group or configure custom settings under the Promo Email Sign-Up tab."
                    action={
                        <Button
                            icon={<EditOutlined />}
                            href={`${import.meta.env.VITE_ADMIN_DASHBOARD_URL}/marketing/promo-codes`}
                            target="_blank"
                        />
                    }
                />
            ),
        [loading, promoCodeSetting, popup],
    );

    return (
        <div className="border border-brand-inkGrey-grey_2 bg-background-inkWhite-white_0 rounded-md p-small shadow-section mb-small">
            <div className="flex justify-between">
                <h6>Modal</h6>
                <div className="flex">
                    <Button onClick={onEdit} icon={<EditOutlined />} className="mr-small" />
                    <ActionConfirmation
                        open={deleteClicked}
                        onOpenChange={setDeleteCLicked}
                        onConfirm={onDelete}
                        onCancel={() => setDeleteCLicked(false)}
                    >
                        <Button onClick={() => setDeleteCLicked(true)} icon={<DeleteOutlined />} />
                    </ActionConfirmation>
                </div>
            </div>
            {renderContent()}
            {renderWarning}
        </div>
    );
}

export default PopupSection;
